import React, { ReactNode, ReactElement } from "react";
import styled from "@material-ui/core/styles/styled";
import pxToRem from "libs/pxToRem";

import waveMobile from "assets/shapes/wave-mobile.svg";
import logo from "assets/logos/yen/mg-yen_logoWhite_pos.svg";
import logoDesktop from "assets/logos/yen/mg-yen_logoColor_pos.svg";
import logoDesktopDev from "assets/logos/yen/mg-yen_logoColor_pos_dev.svg";

import { Link, useLocation } from "react-router-dom";
import homeActive from "assets/icons/ic-home-active.svg";
import homeInActive from "assets/icons/ic-home-inactive.svg";
import infoActive from "assets/icons/ic-info-active.svg";
import infoInActive from "assets/icons/ic-info-inactive.svg";
import analisisActive from "assets/icons/ic_analisis_active.svg";
import analisisInactive from "assets/icons/ic_analisis_inactive.svg";
import confeccionActive from "assets/icons/ic_confeccion_active.svg";
import confeccionInactive from "assets/icons/ic_confeccion_inactive.svg";
import liquidacionActive from "assets/icons/ic_liquidacion_active.svg";
import liquidacionInactive from "assets/icons/ic_liquidacion_inactive.svg";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MuiListItemText from "@material-ui/core/ListItemText";
import Grow from "@material-ui/core/Grow";
import MuiArrowBackIcon from "@material-ui/icons/ArrowBack";

import { mediaBreakpoints } from "assets/mgTheme";
import {
  useTokenPermissionsSection,
  useTokenPermission,
} from "components/hooks/usePermissionsFromToken";
import environment from "environment";
import { useFlags } from "launchdarkly-react-client-sdk";

const NAV_BAR_HEIGHT = 56;

interface INavbarItems {
  [key: string]: {
    label: string;
    to: string;
    activeIcon: any;
    inactiveIcon: any;
    enabled: boolean;
  };
}

interface IProps {
  children: ReactNode;
  topbar?: () => ReactNode;
  onBackClick?: () => void;
}

const NavbarLayout = ({ children, topbar, onBackClick }: IProps) => {
  const navBar = useNavBar();

  return (
    <NavBarLayoutContainer>
      <DesktopNavBar items={navBar} />
      <NavBarLayoutContent>
        {topbar ? (
          topbar()
        ) : (
          <FlexColumn>
            <Figure>
              <MgYenSVG src={logo} alt="MG Web App" />
            </Figure>
          </FlexColumn>
        )}

        {onBackClick && (
          <ArrowBackIcon fontSize="large" onClick={onBackClick} />
        )}
        <GrowAnimation>
          <FlexColumn>{children}</FlexColumn>
        </GrowAnimation>
      </NavBarLayoutContent>
    </NavBarLayoutContainer>
  );
};

const GrowAnimation: React.FC<{ children: ReactElement }> = ({ children }) => {
  const location = useLocation();

  return (
    <Grow key={location.key} in={true}>
      {children}
    </Grow>
  );
};

export default NavbarLayout;

const NavBarLayoutContainer = styled("div")({
  display: "flex",
});

const NavBarLayoutContent = styled("div")({
  backgroundColor: "#f9f7fc",
  flex: 1,
  paddingTop: 0,
  minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
  background:
    "linear-gradient(180deg, rgba(102,62,168,1) 0%, rgba(51,31,84,1) 100%)",
  backgroundSize: "100vw calc(31vh - 90px)",
  backgroundRepeat: "no-repeat",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  [mediaBreakpoints.mobile]: {
    backgroundImage: `url(${waveMobile})`,
    backgroundSize: "100vw calc(31vh - 30px)",
  },
});

const FlexColumn = styled("div")(({ theme }) => ({
  padding: theme.typography.pxToRem(16),
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
}));

const Figure = styled("figure")({
  marginTop: "3vh",
  minHeight: "12vh",
  display: "flex",
  margin: 0,
  [mediaBreakpoints.mobile]: {
    minHeight: "8vh",
  },
});

const MgYenSVG = styled("img")({
  width: "20vh",
  [mediaBreakpoints.mobile]: {
    width: "25vh",
  },
});

const ArrowBackIcon = styled(MuiArrowBackIcon)({
  color: "white",
  marginLeft: 10,
  cursor: "pointer",
});

interface IDesktopNavBar {
  items: INavbarItems;
}

const DesktopNavBar = ({ items }: IDesktopNavBar) => {
  const location = useLocation();
  const selected = `/${location.pathname.substring(1).split("/")[0]}`;

  return (
    <NavBarContainer>
      <NavBarContent>
        <img
          src={environment.name === "production" ? logoDesktop : logoDesktopDev}
          style={{ alignSelf: "flex-start" }} //margin: 20, marginTop: 25
          alt="MG Web App"
        />
        <List component="nav">
          {Object.keys(items)
            .filter((key) => items[key].enabled)
            .map((key) => (
              //MUI5
              //Reemplazar por Styled Component con shouldForwardProps
              <ListItem
                key={`DesktopNavBar-${key}`}
                button
                selected={selected === items[key].to}
                component={Link}
                to={items[key].to}
              >
                <NavBarIcon>
                  {selected === items[key].to ? (
                    <IconFile src={items[key].activeIcon} />
                  ) : (
                    <IconFile src={items[key].inactiveIcon} />
                  )}
                </NavBarIcon>
                <ListItemText
                  primary={items[key].label}
                  selected={items[key].to}
                  custom={selected}
                />
              </ListItem>
            ))}
        </List>
      </NavBarContent>
    </NavBarContainer>
  );
};

const NavBarContainer = styled("div")({
  width: 225,
  height: "100vh",
  background: "#fff",
  display: "flex",
  position: "sticky",
  boxShadow: "0 2px 3px 0 #663ea853",
  top: 0,
});

const NavBarContent = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const NavBarIcon = styled(ListItemIcon)({
  minWidth: 34,
});

interface IItemText {
  selected: string;
  custom: string;
  theme: any;
}

const ListItemText = styled(MuiListItemText)(
  ({ selected, custom, theme }: IItemText) => ({
    "& .MuiListItemText-primary": {
      fontSize: pxToRem(14),
      fontWeight: "bold",
      color: custom === selected ? theme.palette.primary.main : "#757575",
    },
  })
);

interface IIconFile {
  src: string;
}
const IconFile = ({ src }: IIconFile) => {
  return <img alt="" src={src} style={{ width: 24, height: 24 }} />;
};

const useNavBar = (): INavbarItems => {
  const { yenConsumo, yenPrendario } = useFlags();

  return {
    inicio: {
      label: "INICIO",
      to: "/",
      activeIcon: homeActive,
      inactiveIcon: homeInActive,
      enabled: true,
    },
    consumoSolicitudes: {
      label: "SOLICITUDES",
      to: "/solicitudes",
      activeIcon: confeccionActive,
      inactiveIcon: confeccionInactive,
      enabled: yenConsumo,
    },
    consumoRevision: {
      label: "EN ANÁLISIS",
      to: "/revision",
      activeIcon: analisisActive,
      inactiveIcon: analisisInactive,
      enabled: yenConsumo,
    },
    // consumoLiquidar: {
    //   label: "LIQUIDAR",
    //   to: "/liquidar",
    //   activeIcon: liquidacionActive,
    //   inactiveIcon: liquidacionInactive,
    //   enabled: yenConsumo,
    // },
    analisis: {
      label: "ANÁLISIS",
      to: "/analisis",
      activeIcon: analisisActive,
      inactiveIcon: analisisInactive,
      enabled: useTokenPermission("analisis:verListado") && yenPrendario,
    },
    confeccion: {
      label: "CONFECCIÓN",
      to: "/confeccion",
      activeIcon: confeccionActive,
      inactiveIcon: confeccionInactive,
      enabled: useTokenPermission("confeccion:verListado") && yenPrendario,
    },
    liquidacion: {
      label: "LIQUIDACIÓN",
      to: "/liquidacion",
      activeIcon: liquidacionActive,
      inactiveIcon: liquidacionInactive,
      enabled: useTokenPermission("liquidacion:verListado") && yenPrendario,
    },
    /// Por ahora las configuraciones quedan ocualtas para Consumo.
    configuracion: {
      label: "CONFIGURACIÓN",
      to: "/configuracion",
      activeIcon: infoActive,
      inactiveIcon: infoInActive,
      enabled: useTokenPermissionsSection("configuracion") && yenPrendario,
    },
    info: {
      label: "INFO",
      to: "/info",
      activeIcon: infoActive,
      inactiveIcon: infoInActive,
      enabled: true,
    },
  };
};
