import { createActions, handleActions } from "redux-actions";
import jwt_decode from "jwt-decode";
import getMGUser, { IMGUser } from "libs/getMGUser";
import { WebAuth, Auth0Error } from "auth0-js";
import environment from "environment";
import { Reducer } from "redux";
import { RootState } from "redux/reducers";
import store from "store2";

const auth0 = new WebAuth({
  domain: environment.auth0.domain ?? "",
  clientID: environment.auth0.clientId ?? "",
  redirectUri: `${window.location.origin}/callback`,
  responseType: "token id_token",
  scope: "openid profile email offline_access",
  audience: `http://mg-group.com.ar/apis`,
});

interface IActions {
  auth: {
    clearSession: () => void;
    setSession: (session: ISession) => void;
    loginInited: (props: any) => void;
    loginCompleted: (session: ISession) => void;
    loginFailed: (error: any) => void;
  };
}

const {
  auth: { clearSession, setSession, loginInited, loginCompleted, loginFailed },
}: IActions = createActions({
  auth: {
    clearSession: () => ({}),
    setSession: (session: ISession) => ({ ...session }),
    loginInited: (props: any) => ({ props }),
    loginCompleted: (session: ISession) => ({ ...session }),
    loginFailed: (error: any) => ({ error }),
  },
});

const loginSocial = (provider: string) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(loginInited({ provider }));
      auth0.authorize({
        connection: provider,
        prompt: "login",
      });
    } catch (error) {
      console.error(error);
      dispatch(loginFailed(error));
      dispatch(logout());
    }
  };
};

const logout = (returnUrl = "") => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(clearSession());
      auth0.logout({
        returnTo: window.location.origin + returnUrl,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

const handleAuthentication = () => {
  return async (dispatch: any, getState: () => RootState) => {
    auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const auth0TokenData = jwt_decode(authResult.accessToken);
        const user = getMGUser(auth0TokenData);
        const expiresAt =
          (authResult.expiresIn ?? 0) * 1000 + new Date().getTime();

        dispatch(
          loginCompleted({
            accessToken: authResult.accessToken,
            idToken: authResult.idToken,
            expiresAt: expiresAt,
            user: user,
          })
        );
      } else if (err) {
        console.error(
          `Error: ${err.error}. Check the console for further details.`
        );
        dispatch(loginFailed(err));
        dispatch(logout());
      }
    });
  };
};

const renewSession = () => {
  return async (dispatch: any, getState: () => RootState) => {
    auth0.checkSession({}, (err: Auth0Error | null, authResult: any) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        const auth0TokenData = jwt_decode(authResult.accessToken);
        const user = getMGUser(auth0TokenData);
        const expiresAt =
          (authResult.expiresIn ?? 0) * 1000 + new Date().getTime();

        dispatch(
          setSession({
            accessToken: authResult.accessToken,
            idToken: authResult.idToken,
            expiresAt: expiresAt,
            user: user,
          })
        );
      } else if (err) {
        console.error(
          `Could not get a new token (${err.error}: ${err.error_description}).`
        );
        dispatch(logout());
      }
    });
  };
};

interface ISession {
  accessToken: string;
  idToken: string;
  expiresAt: number;
  user: IMGUser | null;
}

interface IState {
  session: ISession | null;
  error: any | null;
  isAlreadyLoggedIn: boolean;
  isLoading: boolean;
}

const initialState: IState = {
  session: null,
  error: null,
  isAlreadyLoggedIn: store("isAlreadyLoggedIn") ? true : false,
  isLoading: false,
};

interface IAction<T> {
  payload: T;
}

const authReducer: Reducer<IState> = handleActions(
  {
    [clearSession as any]: () => {
      store("isAlreadyLoggedIn", false);
      return { ...initialState, isAlreadyLoggedIn: false };
    },
    [setSession as any]: (state: IState, action: IAction<ISession>) => {
      store("isAlreadyLoggedIn", true);
      return {
        session: action.payload,
        isAlreadyLoggedIn: true,
        isLoading: false,
      };
    },
    [loginInited as any]: () => {
      store("isAlreadyLoggedIn", false);
      return {
        ...initialState,
        isAlreadyLoggedIn: false,
        isLoading: true,
      };
    },
    [loginCompleted as any]: (state: IState, action: IAction<ISession>) => {
      store("isAlreadyLoggedIn", true);
      return {
        ...initialState,
        session: action.payload,
        isAlreadyLoggedIn: true,
        isLoading: false,
      };
    },
    [loginFailed as any]: (state: IState, action: IAction<any>) => {
      store("isAlreadyLoggedIn", false);
      return {
        ...initialState,
        error: action.payload,
        isAlreadyLoggedIn: false,
        isLoading: false,
      };
    },
  },
  initialState
);

export default authReducer;
export { loginSocial, logout, handleAuthentication, renewSession };
