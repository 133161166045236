import React, { useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import mgTheme from "assets/mgTheme";
import Home from "components/app/Home";
import { useAuth, useRefreshSession } from "components/app/auth/useAuth";
import Loading from "components/app/Loading";
import Info from "components/app/Info";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import {
  useTokenPermissionsSection,
  useTokenPermission,
} from "components/hooks/usePermissionsFromToken";
import loadable from "@loadable/component";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import "react-toastify/dist/ReactToastify.min.css";
import "assets/toast.css";
import ToastProvider from "./ToastProvider";

const SignIn = loadable(() => import("./auth/SignIn"));
const SignOut = loadable(() => import("./auth/SignOut"));
const Callback = loadable(() => import("./auth/Callback"));
const Unauthorized = loadable(() => import("./auth/Unauthorized"));

const SolicitudesConsumo = loadable(
  () => import("./consumo/Solicitudes/ListaSolicitudes")
);
const SolicitudesRevison = loadable(
  () => import("./consumo/Revision/ListaSolicitudes")
);
const SolicitudesLiquidar = loadable(
  () => import("./consumo/Liquidar/ListaSolicitudes")
);
const LoteLiquidacion = loadable(
  () => import("./consumo/Liquidar/LoteLiquidacion/LoteLiquidacion")
);

const SolicitudesAnalisis = loadable(
  () => import("./analisis/ListaSolicitudes")
);
const SolicitudAnalisis = loadable(
  () => import("./analisis/solicitudAnalisis/SolicitudAnalisis")
);
const VerificacionTelefonica = loadable(
  () =>
    import(
      "./analisis/solicitudAnalisis/verificacionTelefonica/VerificacionTelefonica"
    )
);
const Reformular = loadable(
  () => import("./analisis/solicitudAnalisis/reformular/Reformular")
);
const SolicitudesConfeccion = loadable(
  () => import("./confeccion/ListaSolicitudes")
);
const SolicitudesLiquidacion = loadable(
  () => import("./liquidacion/ListaSolicitudes")
);
const SolicitudConfeccion = loadable(
  () => import("./confeccion/SolicitudConfeccion")
);
const ConfeccionDePrenda = loadable(
  () => import("./confeccion/confeccionDePrenda/ConfeccionDePrenda")
);
const SolicitudLiquidacion = loadable(
  () => import("./liquidacion/SolicitudLiquidacion")
);
const Liquidar = loadable(() => import("./liquidacion/liquidar/Liquidar"));
const SolicitudesAnalisisBDS = loadable(
  () => import("./auditoria/ListaSolicitudesBDS")
);
const SolicitudBDS = loadable(() => import("./auditoria/SolicitudBDS"));
const Usuarios = loadable(() => import("./configuracion/usuarios/Usuarios"));
const ParametrosGlobales = loadable(
  () => import("./configuracion/parametrosGlobales/ParametrosGlobales")
);
const CodigosPostales = loadable(
  () => import("./configuracion/codigosPostales/CodigosPostales")
);
const CalificacionesPersona = loadable(
  () => import("./configuracion/calificacionesPersona/CalificacionesPersona")
);
const Configuracion = loadable(() => import("./configuracion/Configuracion"));

const App = () => {
  const { isAuthenticated, isAlreadyLoggedIn, session } = useAuth();
  const ldClient = useLDClient();
  useRefreshSession();

  useEffect(() => {
    const isDataValid =
      isAuthenticated &&
      session?.user?.email &&
      session?.user?.preferred_username;

    if (isDataValid && ldClient) {
      ldClient.identify({
        key: session?.user?.email,
        email: session?.user?.email,
        name: session?.user?.preferred_username,
      });
    }
  }, [isAuthenticated, ldClient, session]);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            meta: {
              token: session?.accessToken,
            },
          },
        },
      }),
    [session?.accessToken]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={mgTheme}>
        <CssBaseline />
        <ReactQueryDevtools />
        <Router>
          <ToastProvider />
          {isAlreadyLoggedIn && !isAuthenticated && <Loading />}

          {!isAlreadyLoggedIn && !isAuthenticated && <PublicRoutes />}

          {isAuthenticated && !session?.user?.isAuthorized && <Unauthorized />}

          {isAuthenticated && session?.user?.isAuthorized && (
            <AuthenticatedRoutes />
          )}
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact path="/signin">
        <SignIn />
      </Route>
      <Route exact path="/callback">
        <Callback />
      </Route>
      <Redirect to="/signin" /> {/*fallback to login*/}
    </Switch>
  );
};

const AuthenticatedRoutes = () => {
  const { session } = useAuth();

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/signout">
        <SignOut />
      </Route>
      <Route exact path="/info">
        <Info />
      </Route>
      {session?.user?.originacion && PrendarioAuthenticatedRoutes()}
      {session?.user?.euroUserId && ConsumoAuthenticatedRoutes()}
      <Redirect to="/" />
    </Switch>
  );
};

const PrendarioAuthenticatedRoutes = () => {
  const { yenPrendario } = useFlags();

  return [
    <RouteWithPermissions
      exact
      path="/analisis"
      component={SolicitudesAnalisis}
      enabled={useTokenPermission("analisis:verListado") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/solicitudesbds"
      component={SolicitudesAnalisisBDS}
      enabled={useTokenPermission("analisis:verListado") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/solicitudesbds/:solicitudId"
      component={SolicitudBDS}
      enabled={useTokenPermission("analisis:detalle") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/analisis/:solicitudId"
      component={SolicitudAnalisis}
      enabled={useTokenPermission("analisis:detalle") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/analisis/:solicitudId/vt"
      component={VerificacionTelefonica}
      enabled={
        useTokenPermission("analisis:verificacionTelefonica") && yenPrendario
      }
    />,
    <RouteWithPermissions
      exact
      path="/analisis/:solicitudId/reformular"
      component={Reformular}
      enabled={useTokenPermission("analisis:reformular") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/confeccion"
      component={SolicitudesConfeccion}
      enabled={useTokenPermission("confeccion:verListado") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/confeccion/:solicitudId"
      component={SolicitudConfeccion}
      enabled={useTokenPermission("confeccion:detalle") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/confeccion/:solicitudId/confeccionDePrenda"
      component={ConfeccionDePrenda}
      enabled={
        useTokenPermission("confeccion:confeccionarPrenda") && yenPrendario
      }
    />,
    <RouteWithPermissions
      exact
      path="/liquidacion"
      component={SolicitudesLiquidacion}
      enabled={useTokenPermission("liquidacion:verListado") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/liquidacion/:solicitudId"
      component={SolicitudLiquidacion}
      enabled={useTokenPermission("liquidacion:detalle") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/liquidacion/:solicitudId/liquidar"
      component={Liquidar}
      enabled={useTokenPermission("liquidacion:liquidar") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/configuracion"
      component={Configuracion}
      enabled={useTokenPermissionsSection("configuracion") && yenPrendario}
    />,
    <RouteWithPermissions
      exact
      path="/parametros"
      component={ParametrosGlobales}
      enabled={
        useTokenPermission("configuracion:parametrosGlobales") && yenPrendario
      }
    />,
    <RouteWithPermissions
      exact
      path="/codigospostales"
      component={CodigosPostales}
      enabled={
        useTokenPermission("configuracion:codigosPostales") && yenPrendario
      }
    />,
    <RouteWithPermissions
      exact
      path="/calificaciones"
      component={CalificacionesPersona}
      enabled={
        useTokenPermission("configuracion:calificaciones") && yenPrendario
      }
    />,
    <RouteWithPermissions
      exact
      path="/usuarios"
      component={Usuarios}
      enabled={useTokenPermission("configuracion:usuarios") && yenPrendario}
    />,
  ].map((x, y) => ({ ...x, key: `route-prendario-${y}` }));
};

const ConsumoAuthenticatedRoutes = () => {
  const { yenConsumo } = useFlags();
  return [
    <RouteWithPermissions
      exact
      path="/solicitudes"
      component={SolicitudesConsumo}
      enabled={yenConsumo}
    />,
    <RouteWithPermissions
      exact
      path="/revision"
      component={SolicitudesRevison}
      enabled={yenConsumo}
    />,
    <RouteWithPermissions
      exact
      path="/liquidar"
      component={SolicitudesLiquidar}
      enabled={yenConsumo}
    />,
    <RouteWithPermissions
      exact
      path="/loteLiquidacion"
      component={LoteLiquidacion}
      enabled={yenConsumo}
    />,
  ].map((x, y) => ({ ...x, key: `route-consumo-${y}` }));
};

const RouteWithPermissions = ({
  exact,
  path,
  component,
  enabled,
}: {
  exact?: boolean;
  path?: any;
  component?: React.ComponentType<any>;
  enabled: boolean;
}) => {
  return enabled ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    <Redirect to="/" />
  );
};

export default App;
