import jsonFetch from "./jsonFetch";
import environment from "environment";
import convertToQueryString from "./convertToQueryString";

import {
  SolicitudesRequest,
  SolicitudesResponse,
  SolicitudAnalisisRequest,
  SolicitudAnalisisResponse,
  SolicitudPlanesComercialesAnalisisRequest,
  SolicitudPlanesComercialesResponse,
  ModificarBancarizacionTitularRequest,
  ModificarBancarizacionTitularResponse,
  SolicitudPlazosPrestamoRequest,
  SolicitudCalculoPrestamoRequest,
  OfertaResponse,
  ActualizarSituacionLaboralRequest,
  ActualizarSituacionLaboralResponse,
  DatosPersonaCorregidosRequest,
  SolicitudDomicilioRequest,
  SolicitudAgregarDatosContactoRequest,
  SolicitudEliminarTelefonoRequest,
  SolicitudEliminarContactoRedRequest,
  SolicitudVehiculoRequest,
  ReformularSolicitudRequest,
  VerificacionTelefonicaRequest,
  VTResponse,
  DiferenciaNoFinanciadaRequest,
  DesistirSolicitudPrendarioRequest,
  AprobarSolicitudPrendarioRequest,
  RechazarSolicitudPrendarioRequest,
  HabilitarNoAptoRequest,
  LimitesAprobacionSolicitudRequest,
  LimitesAprobacionSolicitudResponse,
  InformesVehiculoSolicitadosRequest,
  AprobarVehiculoRequest,
  ResultadoAnalisisAutomaticoRequest,
  ResultadoAnalisisAutomaticoResponse,
  Sucursal,
  SolicitudesAuditoriaRequest,
  SolicitudConsumoAnalisisResponse,
  SolicitudesConsumoResponse,
  DownloadSolicitudesConsumoResponse,
} from "./types/originacion/services/analisisSolicitudTypes";
import { AcreedorPrendario } from "./types/originacion/businessTypes";

const solicitudes = (token: string) => {
  return {
    getSolicitudes: (
      props: SolicitudesRequest | undefined
    ): Promise<SolicitudesResponse> => {
      const params = props ? `?${convertToQueryString(props)}` : "";

      return jsonFetch<SolicitudesResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/analisis${params}`,
        method: "GET",
      });
    },
    getSolicitudesConsumo: (
      props: SolicitudesRequest | undefined
    ): Promise<SolicitudesConsumoResponse> => {
      const params = props ? `?${convertToQueryString(props)}` : "";

      return jsonFetch<SolicitudesConsumoResponse>({
        token,
        endpoint: `${environment.originacionUrl}/consumo/solicitudes${params}`,
        method: "GET",
      });
    },
    getSolicitudesConfeccion: (
      props: SolicitudesRequest | undefined
    ): Promise<SolicitudesResponse> => {
      const params = props ? `?${convertToQueryString(props)}` : "";

      return jsonFetch<SolicitudesResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/confeccion${params}`,
        method: "GET",
      });
    },
    getSolicitudesLiquidacion: (
      props: SolicitudesRequest | undefined
    ): Promise<SolicitudesResponse> => {
      const params = props ? `?${convertToQueryString(props)}` : "";

      return jsonFetch<SolicitudesResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/liquidacion${params}`,
        method: "GET",
      });
    },
    getSolicitudesAuditoria: (
      props: SolicitudesAuditoriaRequest | undefined
    ): Promise<SolicitudesResponse> => {
      const params = props ? `?${convertToQueryString(props)}` : "";

      return jsonFetch<SolicitudesResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/bancodelsol${params}`,
        method: "GET",
      });
    },
    getSolicitud: (
      props: SolicitudAnalisisRequest
    ): Promise<SolicitudAnalisisResponse> => {
      return jsonFetch<SolicitudAnalisisResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/analisis`,
        method: "GET",
      });
    },
    getSolicitudConsumo: (
      props: SolicitudAnalisisRequest
    ): Promise<SolicitudConsumoAnalisisResponse> => {
      return jsonFetch<SolicitudConsumoAnalisisResponse>({
        token,
        endpoint: `${environment.originacionUrl}/consumo/solicitudes/${props.id}`,
        method: "GET",
      });
    },
    postCorregirDatosPersona: (
      props: DatosPersonaCorregidosRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/personas/${props.tipoPersona}/datoscorregidos`,
        method: "POST",
        jsonBody: props,
      });
    },
    postActualizarDomicilio: (
      props: SolicitudDomicilioRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/personas/${props.tipoPersona}/domicilio`,
        method: "POST",
        jsonBody: props,
      });
    },
    postTitularBancarizado: (
      props: ModificarBancarizacionTitularRequest
    ): Promise<ModificarBancarizacionTitularResponse> => {
      return jsonFetch<ModificarBancarizacionTitularResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/personas/titular/bancarizacion`,
        method: "POST",
        jsonBody: props,
      });
    },
    getPlazosPrestamo: (
      props: SolicitudPlazosPrestamoRequest
    ): Promise<number[]> => {
      return jsonFetch<number[]>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/plazosPrestamo`,
        method: "GET",
      });
    },
    getPlanesComerciales: (
      props: SolicitudPlanesComercialesAnalisisRequest
    ): Promise<SolicitudPlanesComercialesResponse> => {
      const params = props.plazo ? `?plazo=${props.plazo}` : "";
      return jsonFetch<SolicitudPlanesComercialesResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/analisis/planescomerciales${params}`,
        method: "GET",
      });
    },
    getCalculoPrestamo: (
      props: SolicitudCalculoPrestamoRequest
    ): Promise<OfertaResponse> => {
      const params = props ? `?${convertToQueryString(props)}` : "";
      return jsonFetch<OfertaResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/analisis/calculo${params}`,
        method: "GET",
      });
    },
    postActualizarSituacionLaboral: (
      props: ActualizarSituacionLaboralRequest
    ): Promise<ActualizarSituacionLaboralResponse> => {
      return jsonFetch<ActualizarSituacionLaboralResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/personas/${props.tipoPersona}/situacionLaboral`,
        method: "POST",
        jsonBody: props,
      });
    },
    postAgregarDatosContacto: (
      props: SolicitudAgregarDatosContactoRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/contacto`,
        method: "POST",
        jsonBody: props,
      });
    },
    postEliminarContactoTelefono: (
      props: SolicitudEliminarTelefonoRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/contacto/telefono`,
        method: "DELETE",
        jsonBody: props,
      });
    },
    postEliminarContactoRed: (
      props: SolicitudEliminarContactoRedRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/contacto/red`,
        method: "DELETE",
        jsonBody: props,
      });
    },
    postActualizarVehiculo: (
      props: SolicitudVehiculoRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/vehiculo`,
        method: "POST",
        jsonBody: props,
      });
    },
    postReformularSolicitud: (
      props: ReformularSolicitudRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/analisis/reformulacion`,
        method: "POST",
        jsonBody: props,
      });
    },
    postDiferenciaNoFinanciada: (
      props: DiferenciaNoFinanciadaRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/analisis/diferenciaNoFinanciada`,
        method: "POST",
        jsonBody: props,
      });
    },
    postRealizarVT: (props: VerificacionTelefonicaRequest): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/analisis/verificacionTelefonica`,
        method: "POST",
        jsonBody: props,
      });
    },
    getHistorialVT: (solicitudId: number): Promise<VTResponse[]> => {
      return jsonFetch<VTResponse[]>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${solicitudId}/vt/historial`,
        method: "GET",
      });
    },
    desistir: (props: DesistirSolicitudPrendarioRequest): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/estados/desistida`,
        method: "POST",
        jsonBody: props,
      });
    },
    rechazar: (props: RechazarSolicitudPrendarioRequest): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/estados/rechazo`,
        method: "POST",
        jsonBody: props,
      });
    },
    habilitarNoApto: (props: HabilitarNoAptoRequest): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/estados/habilitacion`,
        method: "POST",
      });
    },
    aprobar: (props: AprobarSolicitudPrendarioRequest): Promise<void> => {
      return jsonFetch<void>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/estados/aprobacion`,
        method: "POST",
        jsonBody: props,
      });
    },
    getLimitesAprobacion: (
      props: LimitesAprobacionSolicitudRequest
    ): Promise<LimitesAprobacionSolicitudResponse> => {
      return jsonFetch<LimitesAprobacionSolicitudResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/analisis/limitesaprobacion`,
      });
    },
    postInformesVehiculoSolicitados: (
      props: InformesVehiculoSolicitadosRequest
    ): Promise<void> => {
      return jsonFetch<void>({
        token,
        method: "POST",
        jsonBody: props,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/estados/informesvehiculosolicitados`,
      });
    },
    postAprobarVehiculo: (props: AprobarVehiculoRequest): Promise<void> => {
      return jsonFetch<void>({
        token,
        method: "POST",
        jsonBody: props,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/estados/informesvehiculoaprobados`,
      });
    },
    getSucursales: (): Promise<Sucursal[]> =>
      jsonFetch<Sucursal[]>({
        token,
        endpoint: `${environment.originacionUrl}/nivelesorganizacion/sucursales`,
      }),
    getAcreedoresPrendarios: (): Promise<AcreedorPrendario[]> =>
      jsonFetch<AcreedorPrendario[]>({
        token,
        endpoint: `${environment.originacionUrl}/acreedoresPrendarios`,
      }),
    getSolicitudAnalisisAutomatico: (
      props: ResultadoAnalisisAutomaticoRequest
    ): Promise<ResultadoAnalisisAutomaticoResponse> =>
      jsonFetch<ResultadoAnalisisAutomaticoResponse>({
        token,
        endpoint: `${environment.originacionUrl}/solicitudes/${props.id}/analisis/motivosaprobadonoautomatico`,
      }),
    postDownloadSolicitudesConsumo:
      (): Promise<DownloadSolicitudesConsumoResponse> =>
        jsonFetch<DownloadSolicitudesConsumoResponse>({
          token,
          endpoint: `${environment.originacionUrl}/consumo/reportes/chat-tonic`,
          method: "POST",
          jsonBody: {},
        }),
  };
};

export { solicitudes };
