import React from "react";
import Button from "@material-ui/core/Button/Button";
import { Link } from "react-router-dom";

import NavbarLayout from "components/styled/layouts/navbarLayout/NavbarLayout";
import ContentSectionMaxWidth from "components/styled/contentSection/ContentSectionMaxWidth";
import {
  ButtonGroup,
  ButtonGroupLabel,
} from "components/styled/toolboxButtons/ButtonGroup";
import liquidacionIcon from "assets/icons/ic_liquidacion_color.svg";
import analisisIcon from "assets/icons/ic_analisis_color.svg";
import consumoIcon from "assets/icons/ic_consumo_color.svg";
import confeccionIcon from "assets/icons/ic_confeccion_color.svg";
import { useTokenPermission } from "components/hooks/usePermissionsFromToken";
import { useFlags } from "launchdarkly-react-client-sdk";
import pxToRem from "libs/pxToRem";

const Home = () => {
  const { yenPrendario, yenConsumo } = useFlags();

  return (
    <NavbarLayout>
      <ContentSectionMaxWidth>
        <ButtonGroup marginBottom={35}>
          {yenPrendario && HomePrendario()}
          {yenConsumo && HomeConsumo()}
        </ButtonGroup>
      </ContentSectionMaxWidth>
    </NavbarLayout>
  );
};

const HomeConsumo = () =>
  [
    <Button component={Link} to="/solicitudes" style={{ padding: pxToRem(15) }}>
      <ButtonGroupLabel
        label={"Consumo"}
        subLabel={"Solicitudes"}
        icon={consumoIcon}
      />
    </Button>,
    <Button component={Link} to="/revision" style={{ padding: pxToRem(15) }}>
      <ButtonGroupLabel
        label={"Consumo"}
        subLabel={"Análisis"}
        icon={consumoIcon}
      />
    </Button>,
    // <Button component={Link} to="/liquidar" style={{ padding: pxToRem(15) }}>
    //   <ButtonGroupLabel
    //     label={"Consumo"}
    //     subLabel={"Liquidar"}
    //     icon={consumoIcon}
    //   />
    // </Button>,
  ].map((x, y) => ({ ...x, key: `home-consumo-button-${y}` }));

const HomePrendario = () =>
  [
    useTokenPermission("analisis:verListado") && (
      <Button component={Link} to="/analisis">
        <ButtonGroupLabel
          label={"Análisis"}
          subLabel={"crédito"}
          icon={analisisIcon}
        />
      </Button>
    ),
    useTokenPermission("confeccion:verListado") && (
      <Button component={Link} to="/confeccion">
        <ButtonGroupLabel
          label={"Confección"}
          subLabel={"prenda"}
          icon={confeccionIcon}
        />
      </Button>
    ),
    useTokenPermission("liquidacion:verListado") && (
      <Button component={Link} to="/liquidacion">
        <ButtonGroupLabel
          label={"Liquidación"}
          subLabel={"crédito"}
          icon={liquidacionIcon}
        />
      </Button>
    ),
  ].map((x, y) =>
    x ? { ...x, key: `home-prendario-button-${y}` } : undefined
  );

export default Home;
